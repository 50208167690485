///var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/website/my-app/src/page/home.js

import React from 'react';
import SwiperBanner from '../component/SwiperBanner';
import Section1Home from '../component/Section1-Home';
import SectioniconHome from '../component/Section2-icon-Home';

const Home = () => {
  return (
    <div>

      <SwiperBanner />
      <Section1Home />
      <SectioniconHome />

      {/* Add your home page content here */}
    </div>
  );
}

export default Home;
