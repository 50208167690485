import React from 'react';
import { Navbar, Nav, Container, Offcanvas, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Navbar.scss';  // ไฟล์ CSS สำหรับสไตล์เพิ่มเติม

const OffcanvasExample = () => {
  return (
    <div className='menu-nav'>
      <Navbar expand={false} className="bg-body-tertiary shadow-sm">
        <Container>
          <Navbar.Brand href="/">
            <img
              src="https://afaa.website/s/7efe0b.webp"
              className="d-inline-block align-top"
              alt="Debt Clinic Logo"
            />
          </Navbar.Brand>
          <div className='ml-buton dis-nomo'>
            <Button className="login-btn" href="https://admin.happydebtclinic.com/">
              เข้าสู่ระบบ
            </Button>
            <Button className="login-btn" href="/register">
              สมัครสมาชิก
            </Button>
          </div>

          <Navbar.Toggle aria-controls="offcanvasNavbar" />

          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >
            <Offcanvas.Header closeButton>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/home">หน้าหลัก</Nav.Link>
                <Nav.Link href="/aboutus">เกี่ยวกับเรา</Nav.Link>
                <Nav.Link href="/articles">บทความ</Nav.Link>
                <Nav.Link href="https://admin.happydebtclinic.com/">เข้าสู่ระบบ</Nav.Link>
                <Nav.Link href="/register">สมัครเข้าร่วมโครงการ</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}

export default OffcanvasExample;
