import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/ArticleDetail.scss';

const ArticleDetail = () => {
  const { title } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch('https://py-maracar.afaa.website/services/api/view');
        const data = await response.json();
        
        const formattedData = data.map(item => ({
          id: item.ServiceID,
          title: item.ServiceName,
          category: item.ServiceTypes_Name,  // แก้ไขคีย์ให้ถูกต้อง
          image: item.ImageURL,
          content: item.Description
        }));

        const foundArticle = formattedData.find(a => a.title === decodeURIComponent(title));
        setArticle(foundArticle);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchArticle();
  }, [title]);

  if (!article) return <p>Article not found</p>;

  return (
    <>
      <img
        className='imgar-banner'
        src="https://afaa.website/s/4c199f.webp"
        alt="Banner 2"
      />
      <div className="breadcrumb-nav">
        <Breadcrumb>
          <Breadcrumb.Item href="/">หน้าแรก</Breadcrumb.Item>
          <Breadcrumb.Item href="/articles">
            บทความ
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{article.title}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Container className='pad-tb40'>
        <Row className="justify-content-center">
          <Col md={8}>
            <h1>{article.title}</h1>
            <p>{article.category}</p>
            <img src={article.image} alt={article.title} className="img-fluid" />
            <Card className="mt-4 text-st">
              <Card.Body>
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ArticleDetail;
