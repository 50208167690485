import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLine, } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="text-center text-md-left mb-3 mb-md-0">
            <p>© Copyright www.happydebtclinic.com</p>
          </Col>
          <Col md={6} className="text-center text-md-right">
            <a href="https://www.facebook.com/profile.php?id=61558695579612&locale=th_TH" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://lin.ee/rjLzr09r" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faLine} />
            </a>
    
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
