///var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/website/my-app/src/page/register.js

import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; // Import icons
import { faLine } from '@fortawesome/free-brands-svg-icons'; // Import Line icon
import '../style/Register.scss';

const Register = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    sur_name: '',
    telephone: '',
    email: '',
    password: '',
    birthday: new Date(),  // Set default date
    contact: ''
  });

  const [loading, setLoading] = useState(false);
  const [sponsorId, setSponsorId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [ssSocialMedia, setSsSocialMedia] = useState('');

  const [showPassword, setShowPassword] = useState(false); // Add state for password visibility
  const [ip, setIp] = useState(''); // Add state for storing IP address

  const toast = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sponsorIdFromUrl = params.get('ss');
    if (sponsorIdFromUrl) {
      localStorage.setItem('sponsor_ID', sponsorIdFromUrl);
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 30);
      localStorage.setItem('sponsor_ID_expiry', expiryDate.toISOString());
      setSponsorId(sponsorIdFromUrl);
    }
  }, []);


  useEffect(() => {
    // Fetch user's IP address
    const fetchIp = async () => {
      try {
        const response = await fetch('https://api64.ipify.org?format=json');
        const data = await response.json();
        setIp(data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIp();
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhoneNumber = (number) => {
    const re = /^\d{10,15}$/;
    return re.test(String(number));
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      birthday: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      toast.current.show({ severity: 'error', summary: 'Invalid Email', detail: 'Please enter a valid email address.' });
      return;
    }

    if (!validatePhoneNumber(formData.telephone)) {
      toast.current.show({ severity: 'error', summary: 'Invalid Phone Number', detail: 'Please enter a valid 10-digit phone number.' });
      return;
    }


    setLoading(true);

    const enrichedFormData = {
      ...formData,
      birthday: formData.birthday.toISOString().split('T')[0],
      ip: ip,
      date: new Date().toISOString().slice(0, 10),
      time: new Date().toLocaleTimeString('it-IT'),
      image: "",
    };

    console.log("Submitting the following data to the register API:", JSON.stringify(enrichedFormData));

    try {
      let response = await fetch('https://py-maracar.afaa.website/register/api/insert', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(enrichedFormData),
      });
      if (!response.ok) throw new Error('กรุณาตรวจสอบ Email และ เบอร์โทรศัพท์อีกครั้ง');

      const userData = await response.json();

      const mlmData = [{
        user_id: userData.user_id,
        sponsor_id: sponsorId ? parseInt(sponsorId, 10) : 58,
        team_id: "A1",
        position: 1,
        commission_rate: 6.02
      }];
      console.log("Sending the following data to the MLM API:", JSON.stringify(mlmData));

      response = await fetch('https://py-maracar.afaa.website/mlm/api/insert', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mlmData),
      });

      if (!response.ok) throw new Error('Failed to fetch from MLM API');

      await handleLogin(formData.email, formData.password);

      toast.current.show({ severity: 'success', summary: 'Registration Successful', detail: 'You are now registered.' });
      setFormData({
        first_name: '', sur_name: '', telephone: '', email: '', password: '', birthday: '', contact: ''
      });

    } catch (error) {
      console.error('Error registering user:', error);
      toast.current.show({ severity: 'error', summary: 'Registration Failed', detail: 'Error: ' + error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (email, password) => {
    try {
      const response = await fetch('https://py-maracar.afaa.website/login/api/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) throw new Error('Failed to login');

      const loginData = await response.json();
      localStorage.setItem('token', loginData.token);

      await fetchSocialMediaLink(loginData.user_id); // Pass the correct user_id

    } catch (error) {
      console.error('Error logging in user:', error);
      toast.current.show({ severity: 'error', summary: 'Login Failed', detail: 'Error: ' + error.message });
    }
  };

  const fetchSocialMediaLink = async (userId) => {
    console.log('userId :', userId);
    try {
      const response = await fetch(`https://py-maracar.afaa.website/mlm/api/view?user_id=${userId}&sort_order=desc`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (!response.ok) throw new Error('Failed to fetch social media link');
      console.log('After receiving API response:', response);
      const data = await response.json();

      console.log('Data:', data);

      const socialMediaLink = data.length > 0 ? data[0].ssSocialMedia : 'https://lin.ee/bbl2VRU';
      console.log('Social Media Link:', socialMediaLink);
      setSsSocialMedia(socialMediaLink);

      setShowModal(true);

    } catch (error) {
      console.error('Error fetching social media link:', error);
      toast.current.show({ severity: 'error', summary: 'Fetch Social Media Link Failed', detail: 'Error: ' + error.message });
    }
  };

  const handleFocus = (e) => {
    e.target.parentElement.classList.add('input-focused');
  };

  const handleBlur = (e) => {
    if (e.target.value === '') {
      e.target.parentElement.classList.remove('input-focused');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  return (
    <>
      <img
        className='imgar-banner'
        src="https://afaa.website/s/4c199f.webp"
        alt="Banner 2"
      />

      <Container className="register-container mt-5">
        <Toast ref={toast} />

        <Row>
          <Col className="order-md-1 order-2" xs={12} md={6}>
            <Image src="https://afaa.website/s/13bf02.webp" alt="Image" width="100%" />
          </Col>

          <Col className="mt-3 order-md-2 order-1" xs={12} md={6}>
            <h1>เป็นสมาชิกกับเรา</h1>
            <Card>
              {loading ? <ProgressSpinner /> : (
                <form onSubmit={handleSubmit}>
                  {[
                    { field: 'first_name', label: 'ชื่อ' },
                    { field: 'sur_name', label: 'นามสกุล' },
                    { field: 'telephone', label: 'โทรศัพท์มือถือ' },
                    { field: 'email', label: 'อีเมล' },
                    { field: 'password', label: 'รหัสผ่าน', type: showPassword ? 'text' : 'password' }
                  ].map(({ field, label, type = 'text' }) => (
                    <div className="p-float-label" key={field}>
                      <label htmlFor={field}>{label}</label>
                      <div className="p-inputgroup">
                        <InputText
                          id={field}
                          name={field}
                          type={type}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          value={formData[field]}
                          onChange={handleChange}
                          required
                        />
                        {field === 'password' && (
                          <span className="p-inputgroup-addon" onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="p-float-label">
                    <label htmlFor="birthday">วันเกิด</label>
                    <DatePicker
                      selected={formData.birthday}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="p-float-label">
                    <label htmlFor="contact">ที่อยู่</label>
                    <InputTextarea
                      id="contact"
                      name="contact"
                      value={formData.contact}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      required
                      rows={3}
                    />
                  </div>
                  <Button label="ลงทะเบียน" />
                </form>
              )}
            </Card>
          </Col>
        </Row>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>ยินดีต้อนรับสู่ HAPPYDEBTCLINIC!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              การสมัครสมาชิกและเข้าสู่ระบบของคุณเสร็จสมบูรณ์แล้ว เรายินดีต้อนรับคุณสู่ครอบครัว HAPPYDEBTCLINIC คลิกปุ่ม LINE@ ด้านล่างเพื่อเริ่มต้นการเดินทางของคุณกับเรา!
            </p>
          </Modal.Body>
          <Modal.Footer>
    <Button className="line-button" onClick={() => window.location.href = ssSocialMedia}>
        Go to LINE <FontAwesomeIcon icon={faLine} />
    </Button>
</Modal.Footer>

        </Modal>
      </Container>
    </>
  );
};

export default Register;
