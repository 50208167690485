// src/components/SwiperBanner.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Home.scss';

const SwiperBanner = () => {
  return (
    <>
      <div className="container pad-tb-50">
        <h1 className='text-head-in'>คลินิกแก้หนี้แบบแฮปปี้</h1>
        <p className='text-p-in'>โครงการช่วยเหลือการแก้หนี้แบบยั่งยืนเพื่อช่วยเหลือและให้ความรู้ทางการเงิน</p>

        <div className="row">
       

          <div className="col-12 col-md-8 mb-8 mar-lr-auto">
            <img className='image' src="https://afaa.website/s/ef7e15.webp" alt="Slide 1" />
          </div>

        </div>

      </div>
    </>
  );
};

export default SwiperBanner;
