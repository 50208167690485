import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/AboutUs.scss';

const AboutUs = () => {
  return (
    <>
      <img
        className='imgar-banner'
        src="https://afaa.website/s/4c199f.webp"
        alt="Banner 2"
      />
      <Container className="about-us-container mt-5">

        <Row className="align-items-center">
          <Col md={6} className="text-center">
            <Image src="https://afaa.website/s/39be27.webp" fluid rounded />
          </Col>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Body className='texy-st'>
                <Card.Title className='head-ab'>คลินิแกแก้หนี้แบบแฮปปี้</Card.Title>
                <p>
                  จากอดีตจนถึงปัจจุบัน เรื่องหนี้สิน เป็นเรื่องที่อยู่รอบตัวเราและมีผู้ที่ประสบปัญหาจากการเป็นหนี้สินจำนวนไม่น้อยบางคนอาจจะพลาดพลั้งต้องการโอกาสใหม่ บางคนอาจจะยังไม่เคยรู้จัก เราจากผู้ที่เคยประสบปัญหาจากการเข้าสู่จุดวิบัติหนี้ ทั้งจากการลงทุนและทำธุรกิจส่วนตัวจึงเข้าใจเป็นอย่างมาก จึงอยากที่หาทางออกพร้อมให้คำแนะนำแบบแผนทางการเงินที่แข็งแรงให้กับทุกท่าน.
                </p>
                <p>
                  จึงเกิด โครงการช่วยเหลือเพื่อ “แก้หนี้แบบยั่งยืน” ไม่ให้กลับมาเป็นหนี้เสียอีก ด้วยการเข้าสู่กระบวนการรวบหนี้ ชำระหนี้ รับเงินฉุกเฉิน พิเศษสุดมีเงินทุนก้อนโตให้ไปลงทุน เพื่อเพิ่มรายได้นอกเหนือจากนั้นสามารถสร้างเงินออมได้สูงสุดถึง 12% ต่อปี เพื่อไปสู่การหลุดออกจากวงจรการเป็นหนี้ได้แบบ Easy และ Happy โดยไม่มีค่าใช้จ่ายหรือค่าธรรมเนียมใดๆ
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-center">

          <Col md={6} className='dis-ab-l'>
            <Card className="mb-4">
            <Card.Body className='texy-st'>
                <Card.Title>What We Do?</Card.Title>
                <p>
                  โครงการช่วยเหลือเพื่อ “แก้หนี้แบบยั่งยืน” ไม่ให้กลับมาเป็นหนี้เสียอีก ด้วยการเข้าสู่กระบวนการรวบหนี้ ชำระหนี้ รับเงินฉุกเฉิน พิเศษสุดมีเงินทุนก้อนโตให้ไปลงทุน เพื่อเพิ่มรายได้นอกเหนือจากนั้นสามารถสร้างเงินออมได้ถึง 12% ต่อปี เพื่อไปสู่การหลุดออกจากวงจรการเป็นหนี้ได้แบบ Easy และ Happy โดยไม่มีค่าใช้จ่ายหรือค่าธรรมเนียมใดๆ
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="text-center dis-ab-r">
            <Image src="https://afaa.website/s/cbe040.webp" fluid rounded />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
