import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Ensure this is pointing to the correct CSS import
import '../style/Banner.scss'; // Import the SCSS for styling

const SwiperBanner = () => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 2300,
        disableOnInteraction: false,
      }}
    >
      <SwiperSlide>
        <div className="slide-content">
          <img
            className='img-slide'
            src="https://afaa.website/s/620fb4.webp"
            alt="Banner 1"
          />
        </div>
      </SwiperSlide>

      {/* Repeat similar structure for other slides as needed */}
      <SwiperSlide>
        <div className="slide-content">
          <img
            className='img-slide'
            src="https://afaa.website/s/57778d.webp"
            alt="Banner 2"
          />
          {/* Optionally add more text here if needed */}
        </div>
      </SwiperSlide>


    </Swiper>
  );
};

export default SwiperBanner;
