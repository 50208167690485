import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import AppNavbar from './component/Navbar';
import Home from './page/home';
import Articles from './page/articles';
import Aboutus from './page/aboutus';
import ArticleDetail from './component/ArticleDetail';
import Register from './page/register';
import Footer from './component/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <AppNavbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/register" element={<Register />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/article/:title" element={<ArticleDetail />} /> {/* ตรวจสอบเส้นทางให้ถูกต้อง */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
