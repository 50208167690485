
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../style/Home.scss';

const OnlineServices = () => {
  return (
    <div className="container my-5">
      <h2 className="text-center text-success mb-4">บริการออนไลน์</h2>
      <div className="row justify-content-center">
        <div className="col-12 col-md-2 text-center">
          <div className="service-box p-3 rounded">
            <i className="fas fa-file-download fa-3x text-success mb-3"></i>
            <h5 className="text-success">E-DOCUMENT</h5>
            <p>ดาวน์โหลดเอกสาร</p>
          </div>
        </div>
        <div className="col-12 col-md-2 text-center">
          <div className="service-box p-3 rounded">
            <i className="fas fa-mobile-alt fa-3x text-success mb-3"></i>
            <h5 className="text-success">E-APPLICATION</h5>
            <p>โครงการคลินิกแก้หนี้</p>
          </div>
        </div>
        <div className="col-12 col-md-2 text-center">
          <div className="service-box p-3 rounded">
            <i className="fas fa-check-circle fa-3x text-success mb-3"></i>
            <h5 className="text-success">E-STATUS</h5>
            <p>เช็คสถานะการสมัคร</p>
          </div>
        </div>
        <div className="col-12 col-md-2 text-center">
          <div className="service-box p-3 rounded">
            <i className="fas fa-calendar-check fa-3x text-success mb-3"></i>
            <h5 className="text-success">E-APPOINTMENT</h5>
            <p>นัดหมายลงนามสัญญา</p>
          </div>
        </div>
        <div className="col-12 col-md-2 text-center">
          <div className="service-box p-3 rounded">
            <i className="fas fa-credit-card fa-3x text-success mb-3"></i>
            <h5 className="text-success">E-KNOWLEDGE</h5>
            <p>สาระหนี้</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineServices;
