import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Articles.scss';  // ไฟล์ CSS สำหรับสไตล์เพิ่มเติม

const Articles = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://py-maracar.afaa.website/services/api/view');
        const data = await response.json();
        
        const formattedData = data.map(item => ({
          id: item.ServiceID,
          title: item.ServiceName,
          category: item.ServiceTypes_Name,  // แก้ไขคีย์ให้ถูกต้อง
          image: item.ImageURL,
          content: item.Description
        }));

        setArticles(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <img
        className='imgar-banner'
        src="https://afaa.website/s/4c199f.webp"
        alt="Banner 2"
      />
      <div className="container pad-tb-50">
        <h1 className='text-head-in'>บทความ</h1>
      </div>
      <Container className="article-container">
        <Row>
          {articles.map((article, index) => (
            <Col key={index} md={6} lg={4} className="mb-4">
              <Card className="h-100">
                <Link to={`/article/${encodeURIComponent(article.title)}`} className="card-img-link">
                  <Card.Img variant="top" src={article.image} />
                </Link>
                <Card.Body>
                  <Card.Text className="text-muted">{article.category}</Card.Text>
                  <Card.Title>{article.title}</Card.Title>
                  <Button as={Link} to={`/article/${encodeURIComponent(article.title)}`} variant="link" className="p-0">อ่านต่อ &rarr;</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default Articles;
